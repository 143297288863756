<template>
  <div class="mx-4 text-center">
    <h1>Not Found</h1>
    <p>No hay! <router-link :to="{name: 'Home'}">go home</router-link></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
